import React from 'react';
import {BookMenu} from "./components/BookMenu";
import {HashRouter, Route, Routes} from "react-router-dom";
import axios from "axios";
import TimeAgo from "javascript-time-ago";
import en from 'javascript-time-ago/locale/en';
import {BookRouter} from "./components/BookRouter";
import {createHeliaHTTP} from '@helia/http'
import {IDBBlockstore} from 'blockstore-idb';
import {IDBDatastore} from "datastore-idb";
import {httpGatewayRouting} from "@helia/routers";
import {dagCbor, DAGCBOR} from "@helia/dag-cbor";
import {strings, Strings} from "@helia/strings";
import {Spinner} from "react-bootstrap";
import {trustlessGateway} from '@helia/block-brokers';
import {UUID} from "node:crypto";
import {Helia} from "helia";
import {unixfs, UnixFS} from "@helia/unixfs";
import {IPFSView} from "./components/IPFSView";

function App() {
    React.useMemo(() => {
        axios.defaults.withCredentials = true;
        axios.defaults.baseURL = 'https://fff.michal-atlas.cz/api/';
        axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
        TimeAgo.addDefaultLocale(en);
    }, []);
    const [books, setBooks] = React.useState<UUID[]>([]);
    const [heliaDagcbor, setDagCbor] = React.useState<DAGCBOR>();
    const [heliaStrings, setStrings] = React.useState<Strings>();
    const [heliaUnixfs, setUnixfs] = React.useState<UnixFS>();
    const [helia, setHelia] = React.useState<Helia>();
    React.useEffect(() => {
        const blockstore = new IDBBlockstore('/blocks')
        blockstore.open().then(() => {
            const datastore = new IDBDatastore('/data')
            datastore.open().then(() => {
                createHeliaHTTP({
                    blockstore,
                    datastore,
                    blockBrokers: [
                        trustlessGateway({
                            allowLocal: true,
                            allowInsecure: true,
                        }),
                    ],
                    routers: [
                        httpGatewayRouting({
                            gateways: ['https://ipfs.michal-atlas.cz']
                        })
                    ]

                }).then((helia) => {
                    setDagCbor(dagCbor(helia))
                    setStrings(strings(helia))
                    setUnixfs(unixfs(helia))
                    setHelia(helia)
                })
            })
        })
    }, []);
    const [reload, setReload] = React.useState(false);
    const toggleReload = () => setReload(!reload);
    React.useEffect(
        () => {
            axios.get<UUID[]>('/books')
                .then(r => setBooks(r.data))
        }
        , [reload]);

    React.useEffect(() => {
        updateTheme();
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme)
    }, [])
    const updateTheme = () => {
        const colorMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
        document.querySelector("html")?.setAttribute("data-bs-theme", colorMode);
    }

    if (!heliaStrings || !heliaDagcbor || !heliaUnixfs || !helia) return <><Spinner size={"sm"}/> Loading up Helia...</>
    return (
        <div className="App">
            <HashRouter>
                <Routes>
                    <Route index
                           element={<BookMenu booksReload={toggleReload} books={books} helia={heliaDagcbor}/>}></Route>
                    <Route path="/book/:id/*"
                           element={<BookRouter mdagCbor={heliaDagcbor} booksReload={toggleReload}
                                                heliaStrings={heliaStrings} heliaUnixfs={heliaUnixfs}/>}></Route>
                    <Route path="/ipfs/:cid"
                           element={<IPFSView heliaUnixfs={heliaUnixfs} heliaDagcbor={heliaDagcbor}/>}></Route>
                </Routes>
            </HashRouter>
        </div>
    );
}

export default App;
