import {DAGCBOR} from "@helia/dag-cbor";
import {useParams} from "react-router-dom";
import {StoryNodeRenderer} from "./StoryNode";
import {UnixFS} from "@helia/unixfs";
import {CID} from "multiformats";
import Accordion from "react-bootstrap/Accordion";

export const IPFSView = (props: {
    heliaDagcbor: DAGCBOR;
    heliaUnixfs: UnixFS;
}) => {
    const {cid} = useParams();
    return <>{cid && <Accordion alwaysOpen>
        <StoryNodeRenderer
            heliaUnixfs={props.heliaUnixfs}
            heliaDagcbor={props.heliaDagcbor}
            nodeCid={CID.parse(cid)}/></Accordion>}</>
}