import {useNavigate} from "react-router-dom";
import React from "react";
import {StoryNodeWC} from "../types/Book";
import Accordion from 'react-bootstrap/Accordion';
import {Button, ButtonGroup} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from 'react-helmet';
import {DAGCBOR} from "@helia/dag-cbor";
import {UnixFS} from "@helia/unixfs";
import {StoryNodeRenderer} from "./StoryNode";

export const BookView = (props: {
    heliaUnixfs: UnixFS,
    heliaDagcbor: DAGCBOR,
    node: StoryNodeWC,
    toggleReload: () => void
}) => {
    const node = props.node;
    const navigate = useNavigate();

    return <>
        <Helmet>
            <title>{`BD: ${node.node.title}`}</title>
        </Helmet>
        <h1 className={"display-1"}>{node.node.title}</h1>
        <hr/>
        <Accordion alwaysOpen>
            {node.node?.subNodes?.map(node =>
                <StoryNodeRenderer nodeCid={node} heliaUnixfs={props.heliaUnixfs} heliaDagcbor={props.heliaDagcbor} />
            )}
        </Accordion>
        <hr/>
        <ButtonGroup>
            <Button onClick={() => navigate('./edit')}>Edit</Button>
            <Button onClick={() => navigate('./view')}>View Tree</Button>
        </ButtonGroup>
    </>
}
