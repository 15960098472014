import { Accordion } from "react-bootstrap";
import all from "it-all";
import IframeResizer from "@iframe-resizer/react";
import {CID} from "multiformats";
import React from "react";
import {DAGCBOR} from "@helia/dag-cbor";
import {StoryNode} from "../types/Book";
import {UnixFS} from "@helia/unixfs";
import "./StoryNode.css";

const iframeResizerChildScript = "<script\n" +
    "  src=\"https://cdn.jsdelivr.net/npm/@iframe-resizer/child@5.3.2\"\n" +
    "  type=\"text/javascript\"\n" +
    "  async\n" +
    "></script>";

const darkThemeScript = "" +
    "<script src=\"https://code.jquery.com/jquery.js\"></script>" +
    "<script>" +
    "  const updateTheme = () => {\n" +
    "        const colorMode = window.matchMedia(\"(prefers-color-scheme: dark)\").matches ? \"dark\" : \"light\";\n" +
    "        document.querySelector(\"html\")?.setAttribute(\"data-bs-theme\", colorMode);\n" +
    "    };" +
    "        updateTheme();\n" +
    "        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme)\n" +
    " </script>" +
    "<link href=\"https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css\"" +
    " rel=\"stylesheet\"" +
    " integrity=\"sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH\" crossorigin=\"anonymous\">" +
    "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js\" " +
    "integrity=\"sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz\" " +
    "crossorigin=\"anonymous\">" +
    "</script>";

const NodeTextRenderer = (props: { heliaUnixfs: UnixFS, node: StoryNode }) => {
    const [text, setText] = React.useState<string>("");
    React.useEffect(() => {
        props.node.text && all(props.heliaUnixfs.cat(props.node.text)).then(
            (data) =>
                new Blob([
                    iframeResizerChildScript,
                    ...data,
                    darkThemeScript,
                ]).text().then(setText)
        )
    }, [props.node.text])
    return <>{text && <IframeResizer
        title={props.node.title}
        srcDoc={text}
        style={{height: "30vh", width: "100%"}}
        license={"GPLv3"}
	loading={"lazy"}
    />}</>;
}

export const StoryNodeRenderer = (props: {
    heliaUnixfs: UnixFS,
    heliaDagcbor: DAGCBOR,
    nodeCid: CID }) => {
    const nodeCid = props.nodeCid;
    const [node, setNode] = React.useState<StoryNode>();
    React.useEffect(() => {
        props.heliaDagcbor.get<StoryNode>(nodeCid).then(setNode)
    }, [nodeCid])
    return <>{node && <Accordion.Item eventKey={nodeCid.toString()}>
    <Accordion.Header>{node.title}</Accordion.Header>
    <Accordion.Body><>
        {<NodeTextRenderer heliaUnixfs={props.heliaUnixfs} node={node}/>}
    <Accordion alwaysOpen>
    {node.subNodes?.map((node: CID) =>
            <StoryNodeRenderer heliaDagcbor={props.heliaDagcbor} nodeCid={node} heliaUnixfs={props.heliaUnixfs}/>)}
    </Accordion>
    </>
    </Accordion.Body>
    </Accordion.Item>}</>;
}
